<template>
  <div>
    <van-form ref="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.jobName" readonly label="岗位名称" />
        <van-field v-model="form.schoolYear" readonly label="申请学年" />
        <van-field v-model="form.schoolTerm" readonly label="申请学期" />
        <van-field v-model="form.unitName" readonly label="申请单位" />
        <van-field v-model="form.deptment" readonly label="用工科室" />
        <van-field v-model="form.contactName" readonly label="联系人" />
        <van-field v-model="form.jobType" readonly label="岗位类型" />
        <van-field v-model="form.contactPhone" readonly label="联系电话" />
        <van-field v-model="form.jobDemand" readonly label="岗位需求人数" />
        <van-field v-model="form.workStartAt" readonly label="岗位开始时间" />
        <van-field v-model="form.workEndAt" readonly label="岗位结束时间" />
        <van-field v-model="form.workPlace" readonly label="工作地点" />
        <van-field v-model="form.workContent" readonly label="工作内容" rows="3" />
        <van-field v-model="form.deptOpinion" readonly label="部门意见" rows="3" />
      </van-cell-group>
    </van-form>
  </div>
</template>
<script>
import crudJobSetting from '@/api/modules/daily/jobSetting'
import { mapGetters } from "vuex";
import { codeConvertLabel } from "@/utils";


export default {
  dicts: ['xj_nj', "xj_xqgl", "daily_job_type", "xj_shzt"],
  name: "JobName",
  data() {
    return {
      customRuleFileds: ["schoolYear", "schoolTerm", "workStartAt", "workEndAt"],
      form: {
        id: null,
        jobName: null,
        unitNo: null,
        jobSelect: "校内教学助理",
        unitName: null,
        deptment: null,
        contactName: null,
        contactPhone: null,
        jobType: '1',
        jobDemand: null,
        workStartAt: null,
        workEndAt: null,
        workPlace: null,
        workContent: null,
        schoolYear: null,
        schoolTerm: null,
        deptOpinion: null,
      },
      statusDict: [{
        value: "校内教学助理", label: "校内教学助理"
      },
      {
        value: "科研助理", label: "科研助理"
      },
      {
        value: "行政管理助理", label: "行政管理助理"
      },
      {
        value: "学校公共服务", label: "学校公共服务"
      }],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      crudJobSetting.getById(this.$route.query.id).then((res) => {
        this.$nextTick(() => {
          this.form.schoolTerm = res.schoolTerm === '01' ? "春季学期" : "秋季学期"
          this.form = { ...res }
          let pattern = /[\(|（].*[\)|）]$/
          let content = pattern.exec(res.jobName)
          if (res.jobName && content) {
            let select = this.form.jobName.substring(content.index, this.form.jobName.length)
            this.form.jobSelect = select.includes('校内教学助理')
              ? '校内教学助理'
              : select.includes('科研助理')
                ? '科研助理'
                : select.includes('行政管理助理')
                  ? '行政管理助理'
                  : select.includes('学校公共服务')
                    ? '学校公共服务'
                    : null
          }
        });

      })
    }
  },
  methods: {

  },
}
</script>
<style lang="scss" scoped>
.van-field__label {
  width: 110px;
}
.textarea-style {
  height: 100% !important;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
</style>